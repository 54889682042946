import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, ConfigProvider, Layout, Menu, Row} from "antd";
import {Header} from "antd/es/layout/layout";
import {useLocation, useNavigate} from "react-router-dom";
import {WalletContext} from "./Wallet";

const items = [
    {key: "/", label: "Home"},
    {key: "/mint", label: "Mint"},
    {key: "/pool", label: "Pool"},
    {key: "/swap", label: "Swap"},
    {key: "/game", label: "Game"},
    {key: "/nft", label: "NFT"},
    {key: "/npc", label: "NPC"},
]


function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, 8) + '...' + text.substring(text.length - 5);
    }
    return text;
}


function Navbar() {
    console.log("version 24.04.02");

    const {currentAccount, network, connectWallet} = useContext(WalletContext);

    const [menuItemCurrent, setMenuItemCurrent] = useState('/');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const pathName = location.pathname;
        setMenuItemCurrent(pathName);
    }, [location.pathname]);

    // let currentAccount;
    // let connectWallet;
    return (
        <Layout>
            <Header className={'headerStyle backgroundColor34_34_34'}>
                <Row>
                    <Col span={3}>
                        <div>
                            <div className="imageLogoStyle" style={{float: 'left'}}/>
                            <span className={'fontLogoStyle'}>EggEggSwap</span>
                        </div>
                    </Col>
                    <Col span={11}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Menu: {
                                        itemColor: '#ffffff',
                                        itemHoverColor: '#FFD700',
                                        horizontalItemSelectedColor: '#FFD700',
                                    }
                                },
                            }}
                        >
                            <Menu
                                onClick={(item) => {
                                    console.log(item.key)
                                    navigate(item.key);
                                }}
                                selectedKeys={[menuItemCurrent]}
                                mode="horizontal"
                                items={items}
                                className={'headerStyleMenu custom-menu backgroundColor34_34_34'}/>
                        </ConfigProvider>
                    </Col>


                    <Col span={4} offset={6}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Button: {
                                        defaultBorderColor: '#d9d9d9',
                                        defaultColor: '#d9d9d9',
                                        defaultHoverBorderColor: '#FFD700',
                                        defaultHoverColor: '#FFD700',
                                        defaultActiveBorderColor: '#FFD700',
                                        defaultActiveColor: '#FFD700',
                                        defaultShadow: '0 5px 0 rgba(255, 215, 0, 1)'
                                    }
                                },
                            }}
                        >
                            {/*{network ?*/}
                            {/*    <Button style={{width: '200xp'}} ghost={true}>*/}
                            {/*        <span>{network}</span>*/}
                            {/*    </Button>*/}
                            {/*    :*/}
                            <Button style={{width: '200xp', marginRight: '10px'}} ghost={true}>
                                Sepolia
                            </Button>
                            {/*}*/}
                        </ConfigProvider>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Button: {
                                        defaultBorderColor: '#d9d9d9',
                                        defaultColor: '#d9d9d9',
                                        defaultHoverBorderColor: '#FFD700',
                                        defaultHoverColor: '#FFD700',
                                        defaultActiveBorderColor: '#FFD700',
                                        defaultActiveColor: '#FFD700',
                                        defaultShadow: '0 5px 0 rgba(255, 215, 0, 1)'
                                    }
                                },
                            }}
                        >
                            {currentAccount ?
                                <Button style={{width: '200xp'}} ghost={true}>
                                    <span>{truncateText(currentAccount, 20)}</span>
                                </Button>
                                :
                                <Button style={{width: '200xp'}} ghost={true} onClick={connectWallet}>
                                    Connect Wallet
                                </Button>
                            }
                        </ConfigProvider>
                    </Col>
                </Row>
            </Header>
        </Layout>
    )
}

export default Navbar