import React from 'react'
import {Content} from "antd/es/layout/layout";
import {Layout} from "antd";

function Home() {
    console.log('Home Page');
    return (
        <>
            <div className={'home-background-image background-style-css'}>
                <Layout className={'glass-container'}>
                    <Content className="centered-content contentStyle">
                        <div>
                            <div className='home-overview-div'>
                                <h2>EggEgg Metaverse: a brand new digital world experience, exploring the infinite
                                    possibilities of the blockchain-driven virtual world</h2>
                                <h3>EggEgg Metaverse is a virtual world based on blockchain technology, which
                                    combines
                                    decentralized finance (DeFi) and game elements to provide users with a brand new
                                    digital
                                    world experience. In this virtual world, users can engage in a variety of
                                    activities,
                                    including creating content, trading assets, playing games, and interacting
                                    socially.
                                </h3>
                            </div>
                        </div>

                        <div>
                            <div className={'home-concept-div'}>
                                <h2>Mint: Create unique digital assets</h2>
                                <h3>Mint refers to the process of creating new digital assets in the EggEgg metaverse.
                                    Users
                                    can create unique NFTs through Mint, which can represent virtual land, artwork,
                                    collectibles or any other valuable digital assets. Mint's process usually consumes
                                    certain computing resources, so users need to pay a certain fee. In the EggEgg
                                    metaverse, Mint is a key feature that allows users to convert real-world assets or
                                    ideas
                                    into digital form and trade and display them in the metaverse. Through Mint, users
                                    can
                                    create unique digital assets that can serve as status symbols and as objects of
                                    investment and trading.
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h2>Swap: Flexible Trading Market</h2>
                                <h3>The Swap function allows users to trade NFTs and other cryptocurrencies in the
                                    EggEgg
                                    metaverse. Users can find the NFTs they are interested in here, or sell their
                                    digital
                                    assets. This decentralized trading method ensures the transparency and security of
                                    transactions, while also providing users with more trading opportunities.
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h2>Pool: Liquidity pool for shared income</h2>
                                <h3>Liquidity pools are an important concept in DeFi, which allow users to put their
                                    cryptocurrencies into a shared pool to earn interest or a share of participating
                                    transaction fees. In Dandan Yuanverse, users can put their NFTs into specific pools
                                    to
                                    co-create value with other users’ assets. This shared revenue model encourages
                                    cooperation and sharing among community members.</h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h2>Game: Immersive gaming experience</h2>
                                <h3>Games in the EggEgg metaverse are not just a form of entertainment, they are also an
                                    important way for users to obtain NFTs and cryptocurrencies. Games can be strategic,
                                    adventurous, or casual, and users can earn rewards by completing tasks, defeating
                                    enemies, or participating in competitions. These games not only provide fun, but
                                    also
                                    promote the activity of the NFT market and interaction among users.</h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h2>NFT: Non-fungible digital assets</h2>
                                <h3>NFTs (Non-Fungible Tokens) are the core of EggEgg's metaverse. They represent unique
                                    digital assets. These assets can be virtual lands, characters, props or artwork. NFT
                                    ownership and transaction records are saved on the blockchain, ensuring their value
                                    and scarcity. Users can collect, trade or use NFT to unlock special content in the
                                    game.</h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h2>NPC: Residents of the virtual world</h2>
                                <h3>
                                    NPC (Non-Player Character) is a virtual resident in the game. In the EggEgg
                                    metaverse,
                                    NPC can play various roles, such as businessmen, tour guides, task publishers, etc.
                                    NPCs
                                    can interact with players, provide information, sell goods, or trigger game events
                                    to
                                    participate in the storyline. In the EggEgg metaverse, NPCs provide users with a
                                    more
                                    realistic and immersive gaming experience. Through NPC, users can enjoy richer game
                                    content and better integrate into the game world.
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h2>Token:EGG</h2>
                                <h3>
                                    <p>
                                        EGG is the universal cryptocurrency in the EggEgg metaverse, which can be
                                        circulated
                                        and
                                        traded within the metaverse. Each EGG has its specific purpose and value, and
                                        users
                                        can
                                        choose whether to hold EGG according to their own needs. In games and DeFi
                                        applications,
                                        EGG is often used as a reward mechanism and transaction medium.
                                    </p>
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div className={'home-concept-div'}>
                                <h3>
                                    <p>
                                        The EggEgg Metaverse builds a vibrant digital world through the combination of
                                        these
                                        core concepts. Here, users can freely create, trade and explore, and experience
                                        a
                                        new
                                        lifestyle. With the continuous advancement of technology and the continuous
                                        growth
                                        of
                                        the community, the EggEgg metaverse is expected to become a more open, inclusive
                                        and
                                        prosperous virtual ecosystem.
                                    </p>
                                </h3>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        </>
    )
}

export default Home