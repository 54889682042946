import React, {useContext, useState} from 'react'
import {Button, ConfigProvider, Flex, InputNumber, Layout, Table} from "antd";
import {Content} from "antd/es/layout/layout";
import {WalletContext} from "./Wallet";


const columns = [
    {
        title: 'Height',
        dataIndex: 'height',
        key: 'height',
    },
    {
        title: 'TransactionHash',
        dataIndex: 'transactionHash',
        key: 'transactionHash',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    }
];

function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, 8) + '...' + text.substring(text.length - 5);
    }
    return text;
}

function Mint() {
    const {mintNow} = useContext(WalletContext);

    console.log('Mint Page');

    const [tableDate, setTableDate] = useState([]);
    const [mintValue, setMintValue] = useState('0.1');


    const onChange = (e) => {
        setMintValue(e);
    };

    const onSubmitValue = () => {
        mintNow(mintValue);
    };

    return (
        <>
            <div className={'home-background-image background-style-css'}>
                <Layout className={'glass-container'}>
                    <Content className="centered-content contentStyle">
                        <div style={{
                            width: "100vw",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div className={'div-glass-container mint-pool-swap-left-div'}>

                                <h2> Mint:</h2>
                                <h2> Mint:</h2>

                            </div>
                            <div className={'div-glass-container mint-pool-swap-right-div'}>
                                <div className={"img-task-egg-256"}/>

                                <div className={'mint-right-div-input-button'}>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                InputNumber: {
                                                    activeBorderColor: '#FFD700',
                                                    activeShadow: '0 0 0 5px rgba(255, 215, 0, 1)',
                                                    hoverBorderColor: '#FFD700',
                                                    handleHoverColor: '#FFD700',
                                                }
                                            },
                                        }}
                                    >
                                        <InputNumber
                                            prefix="ETH : "
                                            style={{
                                                width: '20vw',
                                            }}
                                            size={'large'}
                                            defaultValue={mintValue}
                                            max={'1'}
                                            min={'0.1'}
                                            step={'0.1'}
                                            onChange={onChange}
                                        />
                                    </ConfigProvider>

                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Button: {
                                                    defaultBorderColor: '#d9d9d9',
                                                    defaultColor: '#d9d9d9',
                                                    defaultHoverBorderColor: '#FFD700',
                                                    defaultHoverColor: '#FFD700',
                                                    defaultActiveBorderColor: '#FFD700',
                                                    defaultActiveColor: '#FFD700',
                                                    defaultShadow: '0 5px 0 rgba(255, 215, 0, 1)',
                                                    contentFontSizeLG: 20,
                                                    fontWeight: 1000
                                                }
                                            },
                                        }}
                                    >
                                        <Button ghost={true} onClick={onSubmitValue}
                                                style={{width: '20vw'}}
                                                size={"large"}>
                                            Mint now
                                        </Button>
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                    </Content>
                    <Content className="centered-content contentStyle">
                        <div className={'div-table-data'}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerSplitColor: 'hsla(45,80%,80%,.30)',
                                            borderColor: 'hsla(45,80%,80%,.30)'
                                        },
                                    }, token: {
                                        colorBgContainer: 'rgba(0, 0, 0, 0.3) 2px 8px 8px',
                                        colorText: 'white',
                                    }
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={tableDate}
                                    pagination={false}
                                    scroll={{
                                        y: 500,
                                    }}
                                    size="middle"
                                    rowKey={"transactionHash"}
                                    bordered={true}
                                />
                            </ConfigProvider>
                        </div>
                    </Content>
                </Layout>
            </div>
        </>
    )
}

export default Mint