import React from 'react'
import {Content} from "antd/es/layout/layout";
import {Layout} from "antd";

function NPC() {
    console.log('NPC  Page');
    return (
        <>
            <div className={'home-background-image background-style-css'}>
                <Layout className={'glass-container'}>
                    <Content className="centered-content contentStyle">
                        <div style={{
                            width: "100vw",
                            height: "95vh",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white'
                        }}>
                            <h1>NPC is coming soon</h1>
                        </div>
                    </Content>
                </Layout>
            </div>
        </>
    )
}

export default NPC