import React, {useEffect, useState} from "react";
import {ethers} from "ethers";
import {parseEther} from "ethers/lib/utils";

export const WalletContext = React.createContext();
const {ethereum} = window;

const WalletProvider = ({children}) => {

    const [web3Provider, setWeb3Provider] = useState(null);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [network, setNetwork] = useState(null);
    const [signer, setSigner] = useState(null);

    const connectWallet = async () => {
        if (ethereum) {
            try {
                const accounts = await ethereum.request({
                    method: 'eth_requestAccounts',
                });
                const prov = new ethers.providers.Web3Provider(ethereum);
                const signer = await prov.getSigner();
                const network = await prov.getNetwork();

                setWeb3Provider(prov);
                setCurrentAccount(accounts[0]);
                setSigner(signer);
                setNetwork(network.chainId);

                // ethereum.on('accountsChanged', function (accounts) {
                //     console.log('accountsChanged：', accounts[0]);
                //     setCurrentAccount(accounts[0]);
                // });
                //
                // ethereum.on('chainChanged', function (chainId) {
                //     console.log('chainChanged：', chainId);
                //     chainId = parseInt(chainId, 16);
                //     setNetwork(chainId);
                //     window.location.reload();
                // });
                console.log(ethereum)
            } catch (error) {
                console.error('Error connecting wallet:', error);
            }
        } else {
            alert('Please install MetaMask!');
        }
    };


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (ethereum) {
                connectWallet();
            } else {
                console.error('ethereum：', ethereum);
            }
        }, 2000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (ethereum) {

                ethereum.on('accountsChanged', function (accounts) {
                    console.log('accountsChanged：', accounts[0]);
                    setCurrentAccount(accounts[0]);
                });

                ethereum.on('chainChanged', function (chainId) {
                    console.log('chainChanged：', chainId);
                    chainId = parseInt(chainId, 16);
                    setNetwork(chainId);
                });
                console.log(ethereum);
            } else {
                console.error('ethereum：', ethereum);
            }
            // window.location.reload();
        }, 5000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);


    const mintNow = async (e) => {
        console.log('mintValue', e);
        // console.log('signer', signer);
        // if (!signer) {
        //     await connectWallet();
        // } else {
        //     let tx = await signer.sendTransaction({
        //         to: "0x143f13dd5cdfd77ebb53fde26a8dffcc5c29226e",
        //         value: parseEther(e + '')
        //
        //     }).catch(err => {
        //         console.error(err);
        //     })
        //     console.log(tx);
        // }
    }

    const swap = async (amount1, amount2) => {
        console.log('swap', amount1, ' ', amount2);
    }

    const addLiquidity = async (amount1) => {
        console.log('add Liquidity', amount1);
    }

    return (
        <WalletContext.Provider
            value={{
                web3Provider: web3Provider,
                currentAccount: currentAccount,
                network: network,
                signer: signer,
                connectWallet: connectWallet,
                mintNow: mintNow,
                addLiquidity: addLiquidity,
                swap: swap,
            }}
        >
            {children}
        </WalletContext.Provider>
    )
}

export default WalletProvider