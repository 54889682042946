import React, {useEffect, useState} from 'react';

import {Flex, FloatButton, Layout} from 'antd';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './App.css';
import {
    DiscordFilled, DiscordOutlined,
    XOutlined
} from "@ant-design/icons";

import Mint from "./components/Mint";
import Swap from "./components/Swap";
import Pool from "./components/Pool";
import Game from "./components/Game";
import NFT from "./components/NFT";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import NPC from "./components/NPC";
import WalletProvider from "./components/Wallet";


const App = () => {

    return <>
        <WalletProvider>
            <div className={'layoutStyle'}>
                <Flex gap="middle" wrap="wrap">
                    <Layout style={{backgroundColor: 'rgba(255, 255, 255, 0)'}}>
                        <Navbar/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/mint" element={<Mint/>}/>
                            <Route path="/pool" element={<Pool/>}/>
                            <Route path="/swap" element={<Swap/>}/>
                            <Route path="/game" element={<Game/>}/>
                            <Route path="/nft" element={<NFT/>}/>
                            <Route path="/npc" element={<NPC/>}/>
                        </Routes>
                    </Layout>
                </Flex>
                <FloatButton.Group shape="circle" style={{right: 36}}>
                    <FloatButton icon={<XOutlined/>} href={"https://twitter.com/eggeggswap"} target={"_blank"}/>
                    <FloatButton icon={<DiscordOutlined/>} href={"https://discord.gg/CMY95Ey9"} target={"_blank"}/>
                    <FloatButton.BackTop visibilityHeight={0}/>
                </FloatButton.Group>
            </div>
        </WalletProvider>
    </>
};

export default App;