import React, {useContext, useState} from 'react'
import {Content} from "antd/es/layout/layout";
import {Button, Cascader, ConfigProvider, InputNumber, Layout, Table} from "antd";
import {WalletContext} from "./Wallet";


const columns = [
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'ETH',
        dataIndex: 'eth',
        key: 'eth',
    },
    {
        title: 'EGG',
        dataIndex: 'egg',
        key: 'egg',
    },
    {
        title: 'Reward',
        dataIndex: 'reward',
        key: 'reward',
    },
    {
        title: 'Lock Time',
        dataIndex: 'lockTime',
        key: 'lockTime',
    }
];

function Pool() {
    const {addLiquidity} = useContext(WalletContext);

    console.log('Pool Page');

    const [tableDate, setTableDate] = useState([]);
    const [egg2, setEgg2] = useState([]);
    const [swapButtons, setSwapButtons] = useState(true);

    const onChangeAsset1 = (amount1: any) => {
        console.log(amount1)
        const amount2 = amount1 * 10000;
        setEgg2(amount2);
        if (amount1 !== undefined && amount2 !== undefined && amount1 > 0 && amount2 > 0) {
            setSwapButtons(false);
        } else {
            setSwapButtons(true);
        }
    };
    const submitPool = (amount1: any) => {
        addLiquidity(amount1);
    };


    return (
        <>
            <div className={'home-background-image background-style-css'}>
                <Layout className={'glass-container'}>
                    <Content className="centered-content contentStyle">
                        <div style={{
                            width: "100vw",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>

                            <div className={'div-glass-container mint-pool-swap-left-div'}>

                                <h2> Pool:</h2>
                                <h2> Pool:</h2>

                            </div>

                            <div className={'div-glass-container mint-pool-swap-right-div'}>
                                <h1>Pool: Liquidity pool for shared income</h1>
                                <div className={'pool-right-div-input-button'}>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                InputNumber: {
                                                    activeBorderColor: '#FFD700',
                                                    activeShadow: '0 0 0 5px rgba(255, 215, 0, 1)',
                                                    hoverBorderColor: '#FFD700',
                                                    handleHoverColor: '#FFD700',

                                                }
                                            },
                                        }}
                                    >
                                        <InputNumber
                                            prefix="ETH : "
                                            style={{
                                                width: '20vw',
                                            }}
                                            size={'large'}
                                            onChange={onChangeAsset1}
                                        />
                                    </ConfigProvider>
                                    <br/>
                                    <br/>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                InputNumber: {
                                                    activeBorderColor: '#FFD700',
                                                    hoverBorderColor: '#FFD700',
                                                    handleHoverColor: '#FFD700',

                                                }
                                            },
                                        }}
                                    >
                                        <InputNumber
                                            prefix="EGG2 : "
                                            style={{
                                                width: '20vw',
                                            }}
                                            size={'large'}
                                            readOnly={true}
                                            value={egg2}
                                        />
                                    </ConfigProvider>
                                    <br/>
                                    <br/>

                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Button: {
                                                    defaultBorderColor: '#d9d9d9',
                                                    colorTextDisabled: '#d9d9d9',
                                                    defaultHoverBorderColor: '#FFD700',
                                                    defaultHoverColor: '#FFD700',
                                                    defaultActiveBorderColor: '#FFD700',
                                                    defaultActiveColor: '#FFD700',
                                                    defaultShadow: '0 5px 0 rgba(255, 215, 0, 1)',
                                                    contentFontSizeLG: 20,
                                                    fontWeight: 1000
                                                }
                                            },
                                        }}
                                    >
                                        <Button ghost={true} onClick={submitPool} style={{width: '20vw'}}
                                                size={"large"} disabled={swapButtons}>
                                            Add
                                        </Button>
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                    </Content>
                    <Content className="centered-content contentStyle">
                        <div className={'div-table-data'}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerSplitColor: 'hsla(45,80%,80%,.30)',
                                            borderColor: 'hsla(45,80%,80%,.30)'
                                        },
                                    }, token: {
                                        colorBgContainer: 'rgba(0, 0, 0, 0.3) 2px 8px 8px',
                                        colorText: 'white',
                                    }
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={tableDate}
                                    pagination={false}
                                    scroll={{
                                        y: 500,
                                    }}
                                    size="middle"
                                    rowKey={"transactionHash"}
                                    bordered={true}
                                />
                            </ConfigProvider>
                        </div>
                    </Content>
                </Layout>
            </div>
        </>
    )
}

export default Pool